define("plutof/routes/study/add", ["exports", "plutof/components/agent/organization-workgroup", "plutof/components/identifiers/edit", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/routes"], function (_exports, _organizationWorkgroup, _edit, _edit2, _access, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class StudyAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'study.index');
      _defineProperty(this, "title", 'Projects.newP');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const study = this.store.createRecord('study/study');
      if (params.parent) {
        const parent = await this.store.findRecord('study/study', params.parent);
        study.set('study_parent', parent);
      }
      const rightsPromise = (0, _access.create_access_rights)(this.store, study);
      return Ember.RSVP.hash({
        accessRights: rightsPromise,
        itemData: (0, _edit2.createLinkedItemsData)(this.store, study, {
          useEventFiles: false,
          disabledTabs: []
        }),
        projectPersons: [],
        study: study,
        projectAreas: [],
        identifiers: (0, _edit.createIdentifiersEditModel)({
          store: this.store,
          container: study,
          model: 'study/reserved-identifier-block',
          modelContainerField: 'project'
        }),
        workgroupData: (0, _organizationWorkgroup.createDelayedWorkgroupData)(this.store)
      });
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      if (!Ember.isNone(model)) {
        controller.setProperties({
          objectsToDelete: []
        });
      }
    }
  }
  var _default = _exports.default = StudyAddRoute;
});