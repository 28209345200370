define("plutof/adapters/application", ["exports", "@ember-data/adapter/error", "@ember-data/adapter/rest", "ember-inflector", "plutof/config/environment", "plutof/adapters/auth"], function (_exports, _error, _rest, _emberInflector, _environment, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ERROR_MESSAGES = {
    401: 'Unauthorized',
    500: 'Internal Server Error'
  };

  // Extend adapter logics here.
  var _default = _exports.default = _rest.default.extend(_auth.default, {
    defaultSerializer: 'DS/djangoREST',
    addTrailingSlashes: true,
    nonFieldErrorsKey: 'non_field_errors',
    host: Ember.computed(function () {
      return _environment.default.API_HOST;
    }),
    namespace: Ember.computed(function () {
      return _environment.default.API_NAMESPACE;
    }),
    buildURL: function (modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);
      if (this.addTrailingSlashes) {
        if (url.charAt(url.length - 1) !== '/') {
          url += '/';
        }
      }
      return url;
    },
    pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.dasherize(type));
    },
    normalizeErrorResponse: function (status, headers, payload) {
      if (payload && typeof payload === 'object') {
        if (payload.errors) {
          return payload.errors;
        } else {
          return Object.keys(payload).map(key => {
            return {
              status: status,
              title: key,
              detail: payload[key]
            };
          });
        }
      } else {
        return this._super(...arguments);
      }
    },
    shouldReloadRecord(store, snapshot) {
      // XXX: going deep into private API
      // TODO: Clear __partial flag on full load
      const record = snapshot._internalModel._record;
      return record && record.__partial;
    },
    shouldReloadAll: function () {
      // maybe we should write reload:true to find queries instead of overwriting default behaviour.
      return true;
    },
    shouldBackgroundReloadRecord: function (store, snapshot) {
      return false;
    },
    shouldBackgroundReloadAll: function () {
      return false;
    },
    findRecord(store, type, id, snapshot) {
      return this._super(...arguments).then(data => {
        const record = snapshot._internalModel._record;
        if (record) {
          snapshot._internalModel._record.set('__partial', false);
        }
        return data;
      });
    },
    // TODO: We might not need all this and adapter-level error handling might be simplified (so that
    // app-level one doesn't need to try to guess what shape do errors come in)
    handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      } else if (this.isInvalid(status, headers, payload)) {
        // TODO: Caller already parses it, but just in case handle the string a well. Surely JSON.parse
        // was here for a reason
        const jsonPayload = typeof payload === 'string' ? JSON.parse(payload) : payload;
        return new _error.InvalidError(jsonPayload.errors);
      }
      if (Object.getOwnPropertyNames(payload).length === 0) {
        payload = '';
      } else if (payload.detail) {
        payload = payload.detail;
      } else {
        try {
          payload = JSON.parse(payload);
        } catch (err) {
          return payload;
        }
      }
      let errors = this.normalizeErrorResponse(status, headers, payload);
      if (ERROR_MESSAGES[status]) {
        return new _error.default(errors, ERROR_MESSAGES[status]);
      }
      return new _error.default(errors);
    },
    isInvalid: function (status) {
      return status === 400;
    },
    _stripIDFromURL: function (store, snapshot) {
      return this.buildURL(snapshot.modelName);
    }
  });
});