define("plutof/templates/components/navbar/navbar-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YRCiQ2N5",
    "block": "{\"symbols\":[\"parts\",\"&default\",\"&attrs\"],\"statements\":[[11,\"nav\"],[16,0,[31,[\"navbar-base \",[32,0,[\"kindClass\"]]]]],[17,3],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],null,[[\"header\",\"button\",\"info\",\"bookmark\",\"upload\",\"goBack\",\"pagination\"],[[30,[36,0],[\"navbar/-navbar-base/title\"],null],[30,[36,0],[\"navbar/-navbar-base/button\"],null],[30,[36,0],[\"navbar/-navbar-base/info\"],null],[30,[36,0],[\"navbar/-navbar-base/bookmark\"],null],[30,[36,0],[\"navbar/-navbar-base/upload\"],null],[30,[36,0],[\"navbar/-navbar-base/go-back\"],null],[30,[36,0],[\"pagination/page-controls\"],[[\"split\",\"removeSpacing\",\"alwaysShowInterface\",\"class\"],[true,true,true,\"navbar-base__pagination\"]]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"navbar-base__content\"],[12],[2,\"\\n            \"],[18,2,[[32,1],[30,[36,1],null,[[\"header\"],[[30,[36,0],[\"named-slot\"],null]]]]]],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"navbar-base__block navbar-base__block--primary\"],[12],[2,\"\\n                \"],[18,2,[[32,1],[30,[36,1],null,[[\"primaryControls\"],[[30,[36,0],[\"named-slot\"],null]]]]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"navbar-base__block navbar-base__block--secondary\"],[12],[2,\"\\n                \"],[18,2,[[32,1],[30,[36,1],null,[[\"secondaryControls\"],[[30,[36,0],[\"named-slot\"],null]]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/navbar/navbar-base.hbs"
    }
  });
});