define("plutof/components/materialsample/materialsample-table", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/components/occurrences-table", "plutof/misc/abstract", "plutof/mixins/occurrence_row_mixin", "plutof/utils/validations"], function (_exports, _component, _emberCpValidations, _occurrencesTable, _abstract, _occurrence_row_mixin, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MaterialSampleData = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _dec4, _class2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'measurementValidations.isValid': (0, _emberCpValidations.validator)('is-truthy'),
    'itemIsUnique': (0, _emberCpValidations.validator)('is-truthy'),
    'msample.name': (0, _emberCpValidations.validator)('present-not-blank'),
    'msample.location_in_collection': (0, _emberCpValidations.validator)('length', {
      max: 255
    }),
    'msample.subcode': (0, _emberCpValidations.validator)('length', {
      max: 255
    })
  });
  let MaterialSampleEntry = (_dec = Ember.computed.alias('msample'), _dec2 = Ember.computed('msample.size', 'msample.description', 'msample.name', 'msample.location_in_collection', 'msample.subcode'), _dec3 = Ember.computed.alias('validations.isValid'), (_class = class MaterialSampleEntry extends _occurrencesTable.OccurrenceEntry.extend(Validations, _occurrence_row_mixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "occurrence", _descriptor, this);
      _initializerDefineProperty(this, "isValid", _descriptor2, this);
    }
    async init() {
      super.init(...arguments);
      this.loadMeasurementValidations();
      if (!Ember.isNone(this.collection)) {
        this.occurrence.set('deposited_in', await this.collection);
      }
    }
    get defaultValuesAreEmpty() {
      const fields = ['size', 'description', 'name', 'location_in_collection', 'subcode'];
      return fields.map(field => this.get('msample.' + field)).every(Ember.isEmpty);
    }

    // XXX: move to OccurrenceEntry. Also, component has validation context, this could be a child
    // of the whole (however, obs table data can't work under ember-validations, so there is that)
    loadMeasurementValidations() {
      var context = _validations.ValidationContext.create({
        name: 'Measurements'
      });
      this.set('measurementValidations', context);
    }
    save(access) {
      return this.occurrence.save().then(() => this.measurementsData.save());
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "occurrence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "defaultValuesAreEmpty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "defaultValuesAreEmpty"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  class MaterialSampleData extends _occurrencesTable.OccurrencesData {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelPath", 'taxonoccurrence/materialsample/materialsample');
      _defineProperty(this, "measurementsForm", 'materialsample_form');
      _defineProperty(this, "cloneMultivalueMeasurements", true);
    }
    createEntry(msample, measurements) {
      const store = this.store;
      const ownerInjection = Ember.getOwner(store).ownerInjection();
      const collection = Ember.isNone(this.collectionID) ? null : store.findRecord('agent/collection', this.collectionID);
      return MaterialSampleEntry.create(ownerInjection, {
        rowId: this.generateID(),
        msample: msample,
        mainform: this.mainform,
        measurementsData: measurements,
        collection: collection
      });
    }
  }
  _exports.MaterialSampleData = MaterialSampleData;
  MaterialSampleData.initialize = function (store, fileUpload, msamples, fields = {}) {
    if (Ember.isNone(msamples)) {
      msamples = [];
    }
    var data = MaterialSampleData.create((0, _abstract.mergeObjects)({
      entries: [],
      store,
      fileUpload
    }, fields));
    msamples.forEach(ms => data.addOccurrence(ms));
    return data;
  };

  // XXX TODO: Don't derive from OccurrencesTable
  // TODO: Row validations must be declare in row component, not Entry object
  let MaterialSampleTable = (_dec4 = (0, _component.classNames)('materialsample-table'), _dec4(_class2 = class MaterialSampleTable extends _occurrencesTable.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelPath", 'taxonoccurrence/materialsample/materialsample');
      _defineProperty(this, "cloningRules", {
        occurrence: {
          included: ['mainform'],
          excluded: []
        },
        entry: {
          included: ['mainform'],
          excluded: []
        },
        measurements: {
          included: [],
          excluded: []
        }
      });
    }
  }) || _class2);
  var _default = _exports.default = MaterialSampleTable;
});