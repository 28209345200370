define("plutof/templates/layer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DES5uq3f",
    "block": "{\"symbols\":[\"Field\",\"layer\",\"common\",\"navbar\",\"slots\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"layer.index\"]],null],[2,\"\\n\\n\"],[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@pagination\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"area\"],null],[30,[36,1],[\"Layer.layers\"],null],[32,0,[\"pagination\"]],[30,[36,2],[\"goBack\"],null],[30,[36,1],[\"Layer.layers\"],null],\"information/content/gis-lab/layers\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,5,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"canAdd\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,4,[\"button\"]],[[24,0,\"navbar-list__add\"]],[[\"@clicked\",\"@icon\"],[[32,0,[\"add\"]],[30,[36,0],[\"add-new\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,1],[\"General.New\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[4,5]}]]],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],null,[[\"route\",\"model\"],[\"layer.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"description\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "plutof/templates/layer/index.hbs"
    }
  });
});