define("plutof/routes/study/edit", ["exports", "plutof/components/agent/organization-workgroup", "plutof/components/linked-items/edit", "plutof/components/identifiers/edit", "plutof/models/users/usergroup", "plutof/utils/access", "plutof/utils/loading", "plutof/utils/routes", "plutof/utils/store"], function (_exports, _organizationWorkgroup, _edit, _edit2, _usergroup, _access, _loading, _routes, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StudyEditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class StudyEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "groupMembership", _descriptor2, this);
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "modelTitle", 'study');
    }
    async model(params) {
      const store = this.store;
      const project = await store.findRecord('study/study', params.id);
      const workgroupID = project.get('managing_group.id');
      return Ember.RSVP.hash({
        study: project,
        accessRights: (0, _access.get_permissions)(store, this.ajax, project).then(async accessRights => {
          accessRights.set('canModifyPermissions', await this.groupMembership.currentUserBelongs(workgroupID, {
            minStatus: _usergroup.UserStatus.Moderator
          }));
          return accessRights;
        }),
        identifiers: (0, _edit2.loadIdentifiersEditModel)({
          store,
          container: project,
          model: 'study/reserved-identifier-block',
          modelContainerField: 'project',
          filters: {
            project: project.id
          }
        }),
        itemData: (0, _edit.loadLinkedItemsData)(store, project, {
          useEventFiles: false,
          disabledTabs: []
        }),
        projectPersons: (0, _store.query)(store, 'study/studyperson', {
          study: params.id
        }),
        projectAreas: (0, _loading.loadAll)(store, 'sample/project-area', {
          project: params.id
        }, 100),
        workgroupData: workgroupID ? await (0, _organizationWorkgroup.loadWorkgroupData)(store, workgroupID) : null
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        objectsToDelete: [],
        topLevelSamplingAreas: [] // WIP TODO
      });
    }
    transitionToDefaultRoute() {
      return this.transitionTo('study.view', this.currentModel.study.id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupMembership", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = StudyEditRoute;
});