define("plutof/components/auth/identity-provider-buttons/orcid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="btn btn-default social-login-button" {{on "click" @clicked}}>
      <img src="/assets/images/auth/orcid.png" class="social-login-button__image">
      {{i18n-t "login.signin.orcid"}}
  </div>
  
  */
  {
    "id": "efyC+fHw",
    "block": "{\"symbols\":[\"@clicked\"],\"statements\":[[11,\"div\"],[24,0,\"btn btn-default social-login-button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/auth/orcid.png\"],[14,0,\"social-login-button__image\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[\"login.signin.orcid\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/auth/identity-provider-buttons/orcid.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});