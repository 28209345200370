define("plutof/routes/experiment/dna-extraction/edit", ["exports", "plutof/components/experiment/utils", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/reflection", "plutof/utils/routes"], function (_exports, _utils, _edit, _access, _reflection, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const OCCURRENCES = {
    specimens: {
      model: 'taxonoccurrence/specimen/specimen',
      key: 'specimen'
    },
    livingspecimens: {
      model: 'taxonoccurrence/livingculture/strain',
      key: 'living_specimen'
    },
    materialsamples: {
      model: 'taxonoccurrence/materialsample/materialsample',
      key: 'material_sample'
    }
  };
  class DNAExtractionEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "title", 'experiment.extraction.routeTitles.edit');
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const safeParams = Ember.isNone(params) ? {
        specimens: null,
        livingspecimens: null,
        materialsamples: null
      } : params;
      const [experiment, oldMaterials] = await Ember.RSVP.all([this.store.findRecord('dna-lab/dna-extraction', safeParams.id), this.store.query('dna-lab/dna', {
        dna_extraction: safeParams.id,
        include: 'specimen,material_sample,living_specimen'
      })]);
      const [linkedItemsData, accessRights] = await Ember.RSVP.all([(0, _edit.loadLinkedItemsData)(this.store, experiment, {
        useEventFiles: false
      }), (0, _access.create_access_rights)(this.store, experiment)]);
      let newMaterials = [];
      const occParam = Object.keys(OCCURRENCES).find(key => Ember.isPresent(safeParams[key]));
      const occConf = OCCURRENCES[occParam];
      if (Ember.isPresent(occConf)) {
        newMaterials = await Ember.RSVP.all(safeParams[occParam].split(',').map(id => {
          return this.store.findRecord(occConf.model, id).then(occurrence => {
            return this.store.createRecord('dna-lab/dna', {
              [occConf.key]: occurrence
            });
          });
        }));
      }
      const materials = await (0, _utils.makeMaterialStrips)({
        oldMaterials: oldMaterials.toArray(),
        newMaterials: newMaterials.toArray(),
        materialProperties: {
          dna_extraction: experiment
        },
        model: 'dna-lab/dna',
        store: this.store
      });
      const initialMaterialState = {};
      materials.forEach(dna => initialMaterialState[dna.id] = (0, _reflection.serializeRecord)(dna));
      return {
        experiment,
        materials,
        initialMaterialState,
        linkedItemsData,
        accessRights
      };
    }
    transitionToDefaultRoute() {
      return this.transitionTo('experiment.dna-extraction.view', this.currentModel.experiment.id);
    }
  }
  var _default = _exports.default = DNAExtractionEditRoute;
});