define("plutof/routes/analysis/add", ["exports", "plutof/components/analysis/matching-source", "plutof/components/analysis/wrapper-source", "plutof/controllers/analysis/utils", "plutof/utils/routes"], function (_exports, _matchingSource, _wrapperSource, _utils, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AnalysisAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "defaultReturnRoute", 'analysis.index');
      _defineProperty(this, "title", 'Analysis.newAnalysis');
    }
    model(params) {
      const store = this.store;
      const wrapperRun = store.createRecord('analysis/wrapper-run');
      const matchingRun = store.createRecord('analysis/sequence-matching-run');
      return Ember.RSVP.hash({
        wrapperRun,
        matchingRun,
        wrapperSourceData: (0, _wrapperSource.createWrapperSourceData)(store, wrapperRun),
        matchingSourceData: (0, _matchingSource.createMatchingSourceData)(store, matchingRun)
      });
    }

    // TODO: Doesn't get called on route triggerReset
    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('type', null);
      controller.set('name', '');
    }
    initClipboardMatching(pagination, {
      type = 'blastn-sh'
    } = {}) {
      this.controller.switchType((0, _utils.getAnalysisType)(type));
      if (pagination.objectCount > 0) {
        const sourceData = this.controller.model.matchingSourceData;
        if (sourceData.count === 1) {
          const item = sourceData.items[0];
          if (Ember.isNone(item.get('sequence.id')) && Ember.isEmpty(item.external_sequnce) && Ember.isEmpty(item.external_accession_number)) {
            // User has not yet entered anything into mandatory item, so we can safely delete it
            sourceData.remove(item);
          }
        }
        sourceData.setClipboardSequences(pagination);
      }
    }
  }
  var _default = _exports.default = AnalysisAddRoute;
});