define("plutof/components/occurrences-table/-responsive-row/action-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class="btn btn-link occ-table-responsive-row__action" {{on "click" @clicked}} ...attributes>
      <span class="{{@icon}}"></span>
  </button>
  
  */
  {
    "id": "asC4UQw/",
    "block": "{\"symbols\":[\"&attrs\",\"@clicked\",\"@icon\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-link occ-table-responsive-row__action\"],[17,1],[24,4,\"button\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,3]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/occurrences-table/-responsive-row/action-button.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});