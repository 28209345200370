define("plutof/routes/collection/edit", ["exports", "plutof/components/agent/organization-workgroup", "plutof/components/identifiers/edit", "plutof/components/linked-items/edit", "plutof/utils/loading", "plutof/utils/model", "plutof/utils/routes"], function (_exports, _organizationWorkgroup, _edit, _edit2, _loading, _model, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CollectionEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelTitle", 'collection');
      _defineProperty(this, "confirmExit", true);
    }
    async model(params) {
      const collection = await this.store.findRecord('agent/collection', params.id);
      const workgroupID = (0, _model.relationID)(collection, 'managing_group');
      return Ember.RSVP.hash({
        collection,
        workgroupData: workgroupID ? (0, _organizationWorkgroup.loadWorkgroupData)(this.store, workgroupID) : null,
        repositories: (0, _loading.loadAll)(this.store, 'agent/repository', {
          parent_organization: params.id
        }, 100),
        identifiers: workgroupID ? (0, _edit.loadIdentifiersEditModel)({
          store: this.store,
          container: collection,
          model: 'agent/reserved-identifier-block',
          modelContainerField: 'collection',
          filters: {
            collection: collection.id
          }
        }) : null,
        linkedItems: (0, _edit2.loadLinkedItemsData)(this.store, collection, {
          disabledTabs: []
        }),
        objectsToDelete: []
      });
    }
    transitionToDefaultRoute() {
      return this.transitionTo('collection.view', this.currentModel.collection.id);
    }
  }
  _exports.default = CollectionEditRoute;
});