define("plutof/routes/profile/groups/index", ["exports", "plutof/misc/profile_settings", "plutof/utils/pagination", "plutof/utils/routes"], function (_exports, _profile_settings, _pagination, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileGroupsIndexRoute extends _routes.ListRoute {
    async model() {
      const user = await (0, _profile_settings.get_current_user)(this.store);
      const memberships = await (0, _pagination.paginateModel)(this.store, 'users/usergroup', {
        user: user.id
      });
      return {
        user,
        memberships
      };
    }
    setupController() {
      super.setupController(...arguments);
      this.controllerFor('profile').set('currentTab', 'groups');
    }
  }
  var _default = _exports.default = ProfileGroupsIndexRoute;
});