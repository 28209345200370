define("plutof/routes/photobank/edit", ["exports", "plutof/components/edit-preparations", "plutof/components/linked-items/edit", "plutof/misc/config", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/utils/loading", "plutof/utils/model", "plutof/utils/routes", "plutof/utils/store", "plutof/utils/structures"], function (_exports, _editPreparations, _edit, _config, _abstract, _content_types, _loading, _model, _routes, _store, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PhotobankEditRoute = (_dec = Ember.inject.service, (_class = class PhotobankEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'photobank.index');
      _defineProperty(this, "confirmExit", true);
      _defineProperty(this, "modelTitle", 'object');
    }
    async model(params) {
      const store = this.store;
      const object = await store.findRecord('photobank/collectionobject', params.id);
      return Ember.RSVP.hash({
        object: object,
        eventFormData: (0, _model.getOccurrenceSamplingData)(store, object).then(samplingData => {
          samplingData.formData.project = object.get('project');
          samplingData.formData.set('canEditEvent', true);
          return samplingData.formData;
        }),
        additionalIdentifiers: (0, _store.query)(store, 'photobank/additionalidentifier', {
          collection_object: params.id
        }).then(_structures.sortByID),
        itemData: (0, _edit.loadLinkedItemsData)(store, object, {
          useEventFiles: true
        }),
        // TODO: Add pagination support to classification components
        classificationItems: (0, _loading.loadAll)(store, 'photobank/customitem', {
          collection_object: params.id
        }).then(function (items) {
          // XXX
          return Ember.RSVP.all(items.map(function (item) {
            return (0, _abstract.ensure_paths_loaded)(item, ['custom_list_item.custom_classification']);
          })).then(function () {
            return items.toArray();
          });
        }),
        agentItems: (0, _content_types.get_ctype_by_name)(store, 'photobank/collectionobject').then(function (ctype) {
          return (0, _store.query)(store, 'agent/item', {
            content_type: ctype.get('id'),
            object_id: params.id
          });
        }),
        preparationsData: (0, _editPreparations.loadPreparationData)(store, object),
        observationMainform: store.findRecord('measurement/mainform', _config.default.Observation.DEFAULT_MAINFORM_ID)
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('objectsToDelete', []);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = PhotobankEditRoute;
});