define("plutof/components/clipboard/remove-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class="btn btn-default plutof-btn-green" {{on "click" @remove}}>
      {{#if @icon}}
          <span class="{{@icon}}"></span>
      {{/if}}
  
      {{@title}}
  </button>
  
  */
  {
    "id": "NbKsjXlo",
    "block": "{\"symbols\":[\"@icon\",\"@remove\",\"@title\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-default plutof-btn-green\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[32,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/clipboard/remove-button.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});