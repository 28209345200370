define("plutof/routes/sequence/annotation", ["exports", "plutof/utils/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SequenceAnnotationRoute extends _routes.Route {
    // Override default crap trying to resolve model by processing a param name (sequence_id)
    model(params) {
      return {};
    }
  }
  var _default = _exports.default = SequenceAnnotationRoute;
});