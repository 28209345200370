define("plutof/routes/reference/view", ["exports", "plutof/utils/access", "plutof/utils/pagination", "plutof/utils/store", "plutof/utils/routes"], function (_exports, _access, _pagination, _store, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReferenceViewRoute = (_dec = Ember.inject.service, (_class = class ReferenceViewRoute extends _routes.ViewRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'reference.index');
      _defineProperty(this, "modelTitle", 'reference');
    }
    model(params) {
      const referencePromise = this.store.findRecord('reference/reference', params.id);
      return Ember.RSVP.hash({
        reference: referencePromise,
        authors: (0, _store.query)(this.store, 'reference/author', {
          reference: params.id
        }).then(function (authors) {
          return authors.sortBy('id');
        }),
        accessRights: referencePromise.then(reference => (0, _access.get_permissions)(this.store, this.ajax, reference)),
        refbased: (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'taxonoccurrence/referencebased/occurrences', {
          filters: {
            reference: params.id
          }
        })
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        items: [],
        fields: [{
          field: 'title'
        }, {
          field: 'year'
        }, {
          field: 'summary'
        }, {
          field: 'secondary_title'
        }, {
          field: 'place_published'
        }, {
          field: 'volume'
        }, {
          field: 'number_volumes'
        }, {
          field: 'number'
        }, {
          field: 'pages'
        }, {
          field: 'section'
        }, {
          field: 'tertiary_title'
        }, {
          field: 'edition'
        }, {
          field: 'date'
        }, {
          field: 'type_work'
        }, {
          field: 'short_title'
        }, {
          field: 'alternate_title'
        }, {
          field: 'isbn_issn'
        }, {
          field: 'original_publication'
        }, {
          field: 'reprint_edition'
        }, {
          field: 'reviewed_item'
        }, {
          field: 'name_of_database'
        }, {
          field: 'database_provider'
        }, {
          field: 'figures'
        }, {
          field: 'http_url'
        }, {
          field: 'pdf_link'
        }, {
          field: 'label'
        }, {
          field: 'abstract'
        }, {
          field: 'notes'
        }, {
          field: 'research_notes'
        }]
      });
    }
    getTitle(model) {
      return model.reference.representation;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = ReferenceViewRoute;
});