define("plutof/controllers/materialsample/edit", ["exports", "ember-data", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/mixins/edit-controller", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _emberData, _emberCpValidations, _componentValidations, _editController, _access, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  const Validations = (0, _emberCpValidations.buildValidations)({
    'sample.name': (0, _emberCpValidations.validator)('presence', true),
    'sample.location_in_collection': (0, _emberCpValidations.validator)('length', {
      max: 255
    })
  });
  let MaterialsampleEditController = (_dec = Ember.computed.alias('model.sample.mainform.interaction'), _dec2 = Ember.computed.alias('model.sample.deposited_in.id'), _dec3 = Ember.computed('subRepositoryId'), _dec4 = Ember._action, _dec5 = Ember.computed.alias('model.sample'), _dec6 = Ember.computed.alias('model.interaction'), (_class = class MaterialsampleEditController extends Ember.Controller.extend(_editController.default, Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'areaEvent',
        label: 'General.samplingAreaEvent'
      }, {
        name: 'measurements',
        label: 'General.measurements'
      }, {
        name: 'interactions',
        label: 'Interaction.interaction'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
      _defineProperty(this, "recordsToUnload", [{
        record: 'sample'
      }]);
      _initializerDefineProperty(this, "needInteraction", _descriptor, this);
      _initializerDefineProperty(this, "subRepositoryId", _descriptor2, this);
      _initializerDefineProperty(this, "sample", _descriptor3, this);
      _initializerDefineProperty(this, "interaction", _descriptor4, this);
    }
    _save() {
      const {
        sample,
        formData,
        itemData,
        measurementData,
        additionalIdentifiers
      } = this.model;
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      const objectsRemoved = Ember.RSVP.all(this.objectsToDelete.invoke('destroyRecord')).then(() => {
        this.set('objectsToDelete', []);
      });
      return formData.save().then(() => {
        formData.apply(sample);
        return sample.save().then(sample => {
          const saveMeasurements = measurementData.save();
          const saveItems = (0, _access.create_access_rights)(this.store, []).then(accessRights => {
            return itemData.save(sample, accessRights);
          });
          const saveInteractions = this.model.interactions.save();
          const saveAdditionalIDS = Ember.RSVP.all(additionalIdentifiers.filterBy('isNew').invoke('save'));
          const layers = this.model.layers.save([sample]);
          return Ember.RSVP.all([saveMeasurements, saveItems, saveInteractions, saveAdditionalIDS, objectsRemoved, layers]).then(() => {
            (0, _notifications.displayNotification)('success', i18n.t('General.done'));
          });
        });
      });
    }
    get subrepositories() {
      const parent = this.subRepositoryId;
      if (Ember.isNone(parent)) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: this.store.query('agent/repository', {
          parent_organization: parent,
          ordering: 'name'
        })
      });
    }
    save() {
      return this._save().then(() => {
        this.send('goBack');
      }).catch(_notifications.reportError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "needInteraction", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "subRepositoryId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "subrepositories", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "subrepositories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sample", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "interaction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = MaterialsampleEditController;
});