define("plutof/routes/unite/taxonomy", ["exports", "plutof/utils/store", "plutof/utils/unite", "plutof/models/globalkey/threshold", "plutof/utils/routes"], function (_exports, _store, _unite, _threshold, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const DEFAULT_THRESHOLD = '1.5';
  class UNITETaxonomyRoute extends _routes.Route {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "title", 'UniteSH.SHTB');
      _defineProperty(this, "defaultReturnRoute", 'taxonomy-lab');
    }
    async model(params) {
      const thresholds = await (0, _threshold.loadThresholds)(this.store);
      const versions = await (0, _store.findAllFiltered)(this.store, 'globalkey/version', {
        ordering: 'ordering'
      });
      return {
        thresholds,
        threshold: thresholds.findBy('threshold', DEFAULT_THRESHOLD),
        versions,
        version: await (0, _unite.getLastUNITEVersion)(this.store),
        taxon: Ember.isEmpty(params) || Ember.isEmpty(params.taxon_id) ? null : await this.store.findRecord('taxonomy/taxonnode', params.taxon_id)
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('taxon', model.taxon);
    }
  }
  var _default = _exports.default = UNITETaxonomyRoute;
});