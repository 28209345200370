define("plutof/components/plutof-panel/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel">
      <div class="panel-heading">
          <div
              {{on "click" @toggle}}
              class="panel-title-container {{unless @open 'panel-title-container--collapsed'}}"
              data-test="panel-title"
          >
              <h4 class="panel-title {{if @invalid 'panel-title--invalid'}}">
                  {{yield (hash badge=(component "plutof-panel/-panel/badge")) to="title"}}
              </h4>
          </div>
      </div>
  
      <div {{collapse @open}}>
          <div class="panel-body {{if @outer 'no-padding'}}">
              {{yield to="body"}}
          </div>
      </div>
  </div>
  
  */
  {
    "id": "ApL+t6L9",
    "block": "{\"symbols\":[\"@open\",\"@toggle\",\"@invalid\",\"&default\",\"@outer\"],\"statements\":[[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n        \"],[11,\"div\"],[16,0,[31,[\"panel-title-container \",[30,[36,0],[[32,1],\"panel-title-container--collapsed\"],null]]]],[24,\"data-test\",\"panel-title\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n            \"],[10,\"h4\"],[15,0,[31,[\"panel-title \",[30,[36,2],[[32,3],\"panel-title--invalid\"],null]]]],[12],[2,\"\\n                \"],[18,4,[[30,[36,5],[\"title\"],null],[30,[36,4],null,[[\"badge\"],[[30,[36,3],[\"plutof-panel/-panel/badge\"],null]]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"div\"],[4,[38,6],[[32,1]],null],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"panel-body \",[30,[36,2],[[32,5],\"no-padding\"],null]]]],[12],[2,\"\\n            \"],[18,4,[[30,[36,5],[\"body\"],null]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"on\",\"if\",\"component\",\"hash\",\"-named-block-invocation\",\"collapse\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/base.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});