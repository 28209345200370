define("plutof/routes/taxonomy/view", ["exports", "plutof/utils/access", "plutof/utils/notifications", "plutof/utils/routes"], function (_exports, _access, _notifications, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TaxonViewRoute = (_dec = Ember.inject.service, (_class = class TaxonViewRoute extends _routes.ViewRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "modelTitle", 'taxon');
      _defineProperty(this, "defaultReturnRoute", 'taxonomy.trees');
    }
    beforeModel() {
      super.beforeModel(...arguments);

      /* Clear deleted taxon notifcication and infraSpecific taxon notification */
      (0, _notifications.clearNotifications)();
    }
    async model(params) {
      const taxon = await this.store.findRecord('taxonomy/taxonnode', params.id);
      const tree = await taxon.tree;
      const accessRights = (0, _access.get_permissions)(this.store, this.ajax, taxon);
      const taxonDescriptions = await this.store.query('laboratory/taxonomylab/taxondescription', {
        taxon_node: params.id
      });
      return Ember.RSVP.hash({
        taxon,
        tree,
        accessRights,
        taxonDescriptions
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      Ember.run.scheduleOnce('afterRender', controller.showDeletedTaxonMessage);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var _default = _exports.default = TaxonViewRoute;
});